import "@picocss/pico";
import { useState, useEffect } from "react";
import styled from "styled-components";
import data from "./data.json";

export default function WeeklyPage() {
  const [isOpen, setIsOpen] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [inputValue2, setInputValue2] = useState("");
  const [tableData, setTableData] = useState([]);

  const toggleModal = () => {
    setIsOpen(!isOpen);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const newData = {
      name: inputValue,
      maxCount: inputValue2,
      monday: false,
      tuesday: false,
      wednesday: false,
      thursday: false,
      friday: false,
      saturday: false,
      sunday: false,
    };
    setTableData([...tableData, newData]);
    setInputValue("");
    setInputValue2("");
  };

  const calculateCompletionRate = (rowIndex) => {
    const completedCount = Object.values(tableData[rowIndex]).filter(
      (value) => value === true
    ).length;
    const completionRate = Math.round(
      (completedCount / tableData[rowIndex].maxCount) * 100
    );
    return completionRate;
  };

  useEffect(() => {
    fetch("http://127.0.0.1:8090/api/collections/data/records")
      .then((response) => response.json())
      .then((data) => setTableData(data.items));
  }, []);

  const handleCheckboxChange = (rowIndex, columnKey) => {
    const updatedData = [...tableData];
    updatedData[rowIndex][columnKey] = !updatedData[rowIndex][columnKey];
    setTableData(updatedData);
    fetch(
      `http://127.0.0.1:8090/api/collections/data/records/${tableData[rowIndex].id}`,
      {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          [columnKey]: updatedData[rowIndex][columnKey],
        }),
      }
    );
  };

  return (
    <body>
      <main>
        <section className="container ">
          <table>
            <thead>
              <tr>
                <th>목록</th>
                <th>일</th>
                <th>월</th>
                <th>화</th>
                <th>수</th>
                <th>목</th>
                <th>금</th>
                <th>토</th>
                <th>완료율</th>
              </tr>
            </thead>
            <tbody>
              {tableData.map(({ name }, rowIndex) => (
                <tr key={name}>
                  <td style={{ minWidth: "200px" }}>
                    {name}{" "}
                    <a
                      href="#"
                      role="button"
                      style={{
                        padding: "0 4px 0 4px",
                        fontSize: "20px",
                      }}
                      onClick={() => {
                        const newData = [...tableData];
                        newData.splice(rowIndex, 1);
                        setTableData(newData);
                      }}
                    >
                      X
                    </a>
                  </td>
                  <td>
                    <input
                      type="checkbox"
                      checked={tableData[rowIndex]["sunday"]}
                      onChange={() => {
                        handleCheckboxChange(rowIndex, "sunday");
                      }}
                    />
                  </td>
                  <td>
                    <input
                      type="checkbox"
                      checked={tableData[rowIndex]["monday"]}
                      onChange={() => {
                        handleCheckboxChange(rowIndex, "monday");
                      }}
                    />
                  </td>
                  <td>
                    <input
                      type="checkbox"
                      checked={tableData[rowIndex]["tuesday"]}
                      onChange={() => {
                        handleCheckboxChange(rowIndex, "tuesday");
                      }}
                    />
                  </td>
                  <td>
                    <input
                      type="checkbox"
                      checked={tableData[rowIndex]["wednesday"]}
                      onChange={() => {
                        handleCheckboxChange(rowIndex, "wednesday");
                      }}
                    />
                  </td>
                  <td>
                    <input
                      type="checkbox"
                      checked={tableData[rowIndex]["thursday"]}
                      onChange={() => {
                        handleCheckboxChange(rowIndex, "thursday");
                      }}
                    />
                  </td>
                  <td>
                    <input
                      type="checkbox"
                      checked={tableData[rowIndex]["friday"]}
                      onChange={() => {
                        handleCheckboxChange(rowIndex, "friday");
                      }}
                    />
                  </td>
                  <td>
                    <input
                      type="checkbox"
                      checked={tableData[rowIndex]["saturday"]}
                      onChange={() => {
                        handleCheckboxChange(rowIndex, "saturday");
                      }}
                    />
                  </td>

                  <td>{`${calculateCompletionRate(rowIndex)}%`}</td>
                </tr>
              ))}
            </tbody>
          </table>

          <button className="contrast" onClick={toggleModal}>
            +
          </button>

          {isOpen && (
            <dialog open id="modal">
              <form onSubmit={handleSubmit}>
                <label htmlFor="input">추가할 부분을 입력하세요:</label>
                <input
                  type="text"
                  id="input"
                  name="input"
                  value={inputValue}
                  onChange={(event) => setInputValue(event.target.value)}
                />
                <label htmlFor="input">최대 횟수를 입력하세요:</label>
                <input
                  type="text"
                  id="input"
                  name="input"
                  value={inputValue2}
                  onChange={(event) => setInputValue2(event.target.value)}
                />
                <button type="submit">확인</button>
                <button
                  href="#cancel"
                  role="button"
                  className="contrast"
                  onClick={toggleModal}
                >
                  취소
                </button>
              </form>
            </dialog>
          )}
        </section>
      </main>
    </body>
  );
}
