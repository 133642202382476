import React from "react";
import { Link } from "react-router-dom";

import "@picocss/pico";

function Navbar() {
  return (
    <div class="container">
      <nav>
        <ul>
          <li>WEKKLY-APP</li>
        </ul>
        <ul>
          <li>
            <Link to="/">로그인</Link>
          </li>
          <li>회원가입</li>
        </ul>
      </nav>
    </div>
  );
}

export default Navbar;
