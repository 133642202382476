import React from "react";
import { Route, Routes } from "react-router-dom";
import WeeklyPage from "./WeeklyPage";
import LoginPage from "./LoginPage";

const RoutesConfig = () => {
  return (
    <Routes>
      <Route path="/" element={<LoginPage />} />
      <Route path="/weekly" element={<WeeklyPage />} />
    </Routes>
  );
};

export default RoutesConfig;
